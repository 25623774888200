import React from "react";

const Secys = () => {
	const members = [
		{
			year: "2023-24",
			secretaries: ["Manash Kumar", "Prayash Dash", "Yuvraj Singh"],
			coordinators: ["Aakash Sharma", "Abhay Chauhan", "Nischala Pendyala", "Priyaansh Pandey", "Sonam Patel", "Yash Somalkar"],
			coCoordinators: ["Mehul Goyal", "Anant Joshi", "Shlok Saxena", "Sanjana Dutta", "Nachiket Mendki", "Raj Singh", "Kshitij Sharma", "Aryaman Shukla", "Naveen Shukla"]
		},
		{
			year: "2022-23",
			secretaries: ["Aditya Surana", "Pratyush Singh", "Suraj Patil"],
			coordinators: ["Neeraj Kamal", "Sumant Chaudhary", "Srikar Verma", "Preksha Mogra", "Shubham Nehete", "Yuvraj Singh"],
			coCoordinators: ["Aakash Sharma", "Abhay Chauhan", "Manash Kumar", "Nischala Pendyala", "Priyaansh Pandey", "Prayash Dash", "Yuvraj Singh", "Sonam Patel", "Yash Somalkar"]
		},
		{
			year: "2021-22",
			secretaries: ["Harsh Pandey", "Paranjay Garg", "Preety Sarwa"],
			coordinators: ["Shashank Pathak", "Harshwardhan Joshi", "Tanmay Sharma", " Pradeep Sharma", "Aaryam Sthapak", "Amit Baheti"],
			coCoordinators: ["Aditya Surana", "Pratyush Singh", "Neeraj Kamal", "Suraj Patil", "Sumant Chaudhary", "Moulik Ketkar", "Ritika Mishra", "Preksha Mogra", "Shubham Nehete"]
		},
		{
			year: "2020-21",
			secretaries: ["Vaibhav Krishna Singh Raghav", "Aditya Keshari", "Abhishek Upadhyay"],
			coordinators: ["Yash Vinod", "Kushagra Shukla", "Chanchal Meena", "Akash Shukla", "Bhawana Chhachhiya", "Ved Sharda"],
			coCoordinators: ["Shashank Pathak", "Harshwardhan Joshi", "Paranjay Garg", "Tanmay Sharma", "Harsh Pandey", "Pradeep Sharma", "Aaryam Sthapak", "Preety Sarwa", "Amit Baheti"]
		},
		{
			year: "2019-20",
			secretaries: ["Satyam Tyagi", "Govindswaroop Rahangdale", "Ayush Kumar Singh"],
			coordinators: ["Prayshita Sharma", "Chirag Agarwal", "Shreya Munshi", "Jogi Miglani", "Akansh Jain", "Rashmi Deep Mahawar"],
			coCoordinators: ["Yash Vinod", "Kushagra Shukla", "Vaibhav Krishna Singh Raghav", "Chanchal Meena", "Akash Shukla", "Aditya Keshari", "Abhishek Upadhyay", "Bhawana Chhachhiya", "Ved Sharda"]
		},
		{
			year: "2018-19",
			secretaries: ["Yash Agarwal", "Sambhav Jain", "V.S.V. Surya Prakash"],
			coordinators: ["Ashish Sen", "Dikshit Batra", "Ved Vineet", "Saloni Gupta", "Mohit Kumar", "Shruti Patolia"],
			coCoordinators: ["Satyam Tyagi", "Dipesh Kumar", "Chirag Agarwal", "Shreya Munshi", "Jogi Miglani", "Govindswaroop Rahangdale", "Ayush Kumar Singh", "Raghav Sangar", "Akansh Jain"]
		},
		{
			year: "2017-18",
			secretaries: ["Archisha Patki", "Tarush Tiwari", "Vineesha Srivastava"],
			coordinators: ["Ekansh Srivastva", "Manasvi Jangid", "Aman Sapharia", "Samikshya Chand", "Shubhi Srivastava", "Karan Siwach"],
			coCoordinators: ["Yash Agarwal", "Sambhav Jain", "V.S.V. Surya Prakash", "Ashish Sen", "Dikshit Batra", "Ved Vineet", "Saloni Gupta", "Mohit Kumar", "Shruti Patolia"]
		},
		{
			year: "2016-17",
			secretaries: ["Soumyadeep Das", "Asmita Mekala", "Gaurav Somani"],
			coordinators: ["Prabodh Sharma", "Sudhir Narwal", "Akash Singh", "Piyush Patware", "Gaurav Sachdeva", "Tarunandra S."],
			coCoordinators: ["Rahul Sharma", "Ekansh Srivastva", "Manasvi Jangid", "Aman Sapharia", "Samikshya Chand", "Shubhi Srivastava", "Tarush Tiwari", "Archisha Patki", "Karan Siwach"]
		},
		{
			year: "2015-16",
			secretaries: ["Akash Sahu", "Gaurav Tomar", "Rajat Sable"],
			coordinators: ["Bhavya Pruthi", "Akshay Paliwal", "Himank Airon", "Arpit Gurnani", "Anuj Maingi", "Mayank Morya"],
			coCoordinators: ["Asmita Mekala", "Gaurav Somani", "Soumyadeep Das", "Prabodh Sharma", "Sudhir Narwal", "Akash Singh", "Piyush Patware", "Gaurav Sachdeva", "Tarunandra S."]
		},
		{
			year: "2014-15",
			secretaries: ["Nikhil Khatavkar", "Vivek Gupta", "Jyoti Sinha"],
			coordinators: ["Ranjit Kumar", "Kumar Ankur", "Yash Shah", "Avaneesh Gupta", "Shivanju Awasthi", "Nikhilesh Tripathi"],
			coCoordinators: ["Akash Sahu", "Gaurav Tomar", "Rajat Sable", "Bhavya Pruthi", "Tushar Dhande", "Himank Airon", "Arpit Gurnani", "Anuj Maingi", "Mayank Morya"]
		},
		{
			year: "2013-14",
			secretaries: ["Himanshu Gangwar", "P. Manasa"],
			coordinators: ["Shubham Gupta", "Juhi Chaplot", "Paresh Parimal", "Anuruddh Mishra"],
			coCoordinators: ["Nikhil Khatavkar", "Vivek Gupta", "Yash Shah", "Avaneesh Gupta", "Shivanju Awasthi", "Nikhilesh Tripathi"]
		},
		{
			year: "2012-13",
			secretaries: ["Praveen Sharma", "Saummay Bagga"],
			coordinators: ["Bansari Shah", "Anas Feroz", "Sujit Kumar", "Rittic Kashyap"],
			coCoordinators: ["Himanshu Gangwar", "P. Manasa", "Juhi Chaplot", "Paresh Parimal", "Anuruddh Mishra", "Shubham Gupta"]
		}
	];

	return (
		<div class="mx-auto px-4 sm:px-8">
			<div class="py-8 w-5/6 mx-auto">
				<div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
					<div class="inline-block min-w-full overflow-hidden">
						{
							members.map(data => {
								return (
									<>
										<div class="flex justify-center my-5">
											<div class="text-gray-200 text-4xl">{data.year}</div>
										</div>
										<table class="min-w-full leading-normal mb-10">
											<tbody>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-t-lg">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Secretaries</p>
														</div>
														<div class="w-1/2 text-center">
															<p class="relative pr-2 text-gray-800 text-sm">
																<ul>
																{
																	data.secretaries.map(
																		secretary => {
																			return <li>{secretary}</li>
																		}
																	)
																}
																</ul>
															</p>
														</div>
													</td>
												</tr>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-none">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Coordinators</p>
														</div>
														<div class="w-1/2 text-center">
															<p class="relative pr-2 text-gray-800 text-sm">
															<ul>
																{
																	data.coordinators.map(
																		coordinator => {
																			return <li>{coordinator}</li>
																		}
																	)
																}
																</ul>	
															</p>
														</div>
													</td>
												</tr>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-b-lg">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Co-Coordinators</p>
														</div>
														<div class="w-1/2 text-center">
														<p class="relative pr-2 text-gray-800 text-sm">
															<ul>
																{
																	data.coCoordinators.map(
																		coCoordinator => {
																			return <li>{coCoordinator}</li>
																		}
																	)
																}
																</ul>	
															</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Secys;
