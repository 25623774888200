import React from "react";

const RedshiftTeam = () => {
	const members = [
		{
			year: "2023-24",
			head: ["Satvik Sengar"],
			verticalLeads: ["Nandini Dhakaan", "Raghav Sangar"],
			unitCoordinators: ["Ramesh Kumar", "Madhur Sahu", "Aaryaman Shukla", "Yatnesh Gyani", "Sparsh Kandpal", "Aryan Gupta"]
		},
		{
			year: "2022-23",
			head: ["Akshita Gupta"],
			verticalLeads: ["Varun Pandey", "Sanskriti Garg"],
			unitCoordinators: ["Satvik Sengar", "Nandini Dhakaan", "Abhishek Maurya", "Daksh Chaudhary", "Raghav Sangar", "Kushagr Kapoor"]
		},
		{
			year: "2021-22",
			head: ["Paranjay Garg"],
			verticalLeads: ["Purushottam Tiwari", "Piyush Dubey"],
			unitCoordinators: ["Akshita Gupta", "Anjikya Shrivas", "Varun Pandey", "Pratyush Singh", "Sanskriti Garg", "Aarzoo Mittal"]
		},
		{
			year: "2020-21",
			head: ["Yash Vinod"],
			verticalLeads: ["Saksham Sharma", "Ankit Susne"],
			unitCoordinators: ["Harsh Pandey", "Purushottam Tiwari", "Paranjay Garg", "Rishija Rishi Raj", "P S Priyadarshini", "Piyush Dubey"]
		},
		{
			year: "2019-20",
			head: ["Prayshita Sharma"],
			verticalLeads: ["Rahul Vishwakarma", "Ayush Singh"],
			unitCoordinators: ["Yash Vinod", "Ankit Susne", "Akash Shukla", "Saksham Sharma", "Bharat Vaishnav", "Kartikey Pandey"]
		},
		{
			year: "2018-19",
			head: ["V.S.V. Surya Prakash"],
			verticalLeads: ["Anant Raj", "Rashi Jain"],
			unitCoordinators: ["Govind Swaroop", "Yash Agrawal", "Prayshita Sharma", "Palak Chaudhary"]
		}
	];

	return (
		<div class="mx-auto px-4 sm:px-8">
			<div class="py-8 w-5/6 mx-auto">
				<div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
					<div class="inline-block min-w-full overflow-hidden">
						{
							members.map(data => {
								return (
									<>
										<div class="flex justify-center my-5">
											<div class="text-gray-200 text-4xl">{data.year}</div>
										</div>
										<table class="min-w-full leading-normal mb-10">
											<tbody>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-t-lg">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Head</p>
														</div>
														<div class="w-1/2 text-center">
															<p class="relative pr-2 text-gray-800 text-sm">
																<ul>
																{
																	data.head.map(
																		head => {
																			return <li>{head}</li>
																		}
																	)
																}
																</ul>
															</p>
														</div>
													</td>
												</tr>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-none">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Vertical Leads</p>
														</div>
														<div class="w-1/2 text-center">
															<p class="relative pr-2 text-gray-800 text-sm">
															<ul>
																{
																	data.verticalLeads.map(
																		verticalLeads => {
																			return <li>{verticalLeads}</li>
																		}
																	)
																}
																</ul>	
															</p>
														</div>
													</td>
												</tr>
												<tr>
													<td class="flex items-center justify-around py-5 border-b border-gray-200 bg-gray-300 text-md rounded-b-lg">
														<div class="w-1/2 text-center">
															<p class="text-gray-900 font-semibold">Unit Coordinators</p>
														</div>
														<div class="w-1/2 text-center">
														<p class="relative pr-2 text-gray-800 text-sm">
															<ul>
																{
																	data.unitCoordinators.map(
																		unitCoordinator => {
																			return <li>{unitCoordinator}</li>
																		}
																	)
																}
																</ul>	
															</p>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default RedshiftTeam;
